//Styles added to fix the issue with zoom in on iphone
// https://blog.osmosys.asia/2017/01/05/prevent-ios-from-zooming-in-on-input-fields/

// iPhone < 5:
@media screen and (device-aspect-ratio: 2/3) {
  .ql-editor[contenteditable='true'], // prevent Quill text editor default zoom in behaviour
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px !important;
  }
}

// iPhone 5, 5C, 5S, iPod Touch 5g
@media screen and (device-aspect-ratio: 40/71) {
  .ql-editor[contenteditable='true'],
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px !important;
  }
}

// iPhone 6, iPhone 6s, iPhone 7 portrait/landscape
@media screen and (device-aspect-ratio: 375/667) {
  .ql-editor[contenteditable='true'],
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px !important;
  }
}

// iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus portrait/landscape
@media screen and (device-aspect-ratio: 9/16) {
  .ql-editor[contenteditable='true'],
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px !important;
  }
}
// iPhone X, iPhone 11
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .ql-editor[contenteditable='true'],
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px !important;
  }
}

// iPhone  8
@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  .ql-editor[contenteditable='true'],
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px !important;
  }
}

// iPhone  8+
@media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
  .ql-editor[contenteditable='true'],
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px !important;
  }
}
