.container {
  position: relative;
  padding-bottom: 16px;
  border-bottom: 4px solid;
  border-color: var(--gray);
  margin-bottom: 16px;
  opacity: 0.6;
}

.active {
  border-color: var(--success);
  opacity: 1;
}

.clickable {
  cursor: pointer;
}

.icon {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: -16px;
  padding: 3px;
  width: 28px;
  height: 28px;
  font-size: 12px;
  color: var(--gray);
  border: 2px solid;
  border-color: var(--gray);
  border-radius: 50%;
  background: var(--white);
}

.iconActive {
  color: var(--success);
  border-color: var(--success);
}
