@import "~@fontsource/inter/variable.css";

// import icons
@import 'icons';

// If you want to override variables do it here
@import 'variables';

@import '~bootswatch/dist/cosmo/variables';

// Import styles
@import '~@coreui/coreui/scss/coreui.scss';

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

@import '~bootswatch/dist/cosmo/bootswatch';

// If you want to add something do it here
@import 'custom';

// ie fixes
@import 'ie-fix';

// iPhone fixes
@import 'iphone-fix';
