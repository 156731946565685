.main {
  margin-top: 1.5rem;
}

// fix for IE
.navbar-brand {
  img {
    max-width: 155px;
  }
}
.navbar-brand-minimized {
  img {
    max-width: 50px;
  }
}
