// Variable overrides

$white: #fff;
$gray-100: #f5f5f5;
$gray-200: #d8d8d8;
$gray-300: #d8d8d8;
$gray-400: #999999;
$gray-500: #999999;
$gray-600: #676767;
$gray-700: #676767;
$gray-800: #2c2e30;
$gray-900: #2c2e30;
$black: #000;

$blue: #3f3db1;
$indigo: #201f7b;
$purple: #e4ddff;
$pink: #f4f1ff;
$red: #cc5623;
$orange: #f8d6c8;
$yellow: #aba30b;
$green: #3a7e67;
$teal: #d5eee5;
$cyan: #97d7f3;

$enable-rounded: true;

$body-bg: $white;

$font-family-sans-serif: InterVariable, ui-sans-serif, system-ui, -apple-system,
  BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
  sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
  Noto Color Emoji;
$font-size-base: 0.875rem;

$headings-font-weight: 400;

$footer-bg: $white;
