html {
  scroll-behavior: smooth;
}

@include media-breakpoint-down(sm) {
  .main .container-fluid {
    padding: 0 8px;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-default {
  cursor: default !important;
}

.highlight-on-hover {
  &:hover {
    background-color: var(--light);
  }
}

.overflow-x-hidden {
  overflow-x: hidden;
}

@include media-breakpoint-down(sm) {
  .sm-full-width {
    width: 100% !important;
    right: 0 !important;
    left: 0 !important;
    max-width: 100% !important;
  }

  .sm-min-vh-100 {
    min-height: 100vh !important;
  }

  .sm-no-padding {
    padding: 0 !important;
  }

  .sm-no-margin {
    margin: 0 !important;
  }

  .sm-auto-height {
    min-height: auto !important;
  }
}

.modal-full-width {
  @include media-breakpoint-up(sm) {
    max-width: 90% !important;
  }
}

// v3 overrides
.app-footer {
  background-color: $white;
}
.card-header {
  background-color: $white;
}
.nav-tabs {
  .nav-link {
    color: $gray-600;
    &:hover {
      cursor: pointer;
      background: $white;
      border-color: transparent;
      border-bottom-color: $blue;
      color: $blue;
    }
    &.active {
      color: $blue;
      background: $white;
      border-color: transparent;
      border-bottom-color: $blue;
      border-bottom-width: 2px;
      &:focus {
        background: $white;
        border-color: transparent;
        border-bottom-color: $blue;
      }
    }
  }
}

.form-control:focus {
  border-color: $blue;
  box-shadow: $white 0px 0px 0px 0px, $blue 0px 0px 0px 1px,
    $gray-100 0px 1px 2px 0px;
  outline-color: transparent;
}

input[type='radio'] {
  &::after, &:checked::after {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: var(--white);
    content: '';
    display: inline-block;
    visibility: visible;
  }
  &::after {
    border: 2px solid var(--purple);
  }
  &:checked::after {
    border: 4px solid var(--primary);
  }
  &:disabled{
    opacity: 70%;
  }
}
