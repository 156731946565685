.container {
  position: relative;
}

.containerShown {
  border-style: dashed !important;
}

.badgeContainer {
  position: absolute;
  right: -1px;
  opacity: 0.8;
}
