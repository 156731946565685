.wrapper {
  position: relative;

  width: 100%;
  height: 100%;

  &_fullPage {
    height: 100vh;
  }
}

.container {
  text-align: center;
  width: auto;
  max-width: 350px;
  margin: 0 auto;

  &_fullPage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & > img {
    padding: 12px 0;
  }
}
